import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import CountrySelector from "../../components/Selector";
import { COUNTRIES } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addNewClient } from "../../redux/actions/clientActions";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import ConfirmationModal from "../../components/ConfirmationModal";

function AddClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const [fullName, setFullName] = useState("");
  const [errorfullName, setErrorfullName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const clientAdd = useSelector((state) => state.createNewClient);
  const { loadingClientAdd, errorClientAdd, successClientAdd } = clientAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successClientAdd) {
      setFullName("");
      setErrorfullName("");

      setLastName("");
      setErrorLastName("");

      setEmail("");
      setEmailError("");

      setPhone("");
      setErrorPhone("");

      setOtherPhone("");
      setOtherPhoneError("");

      setDateNaissance("");
      setErrorDateNaissance("");

      setLangue("");
      setLangueError("");

      setStatus("");
      setStatusError("");

      setType("");
      setTypeError("");

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successClientAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/clients/">
            <div className="">Clients</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Add</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Add a new client
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection
                title="Client Informations"
                styles="bg-primary text-white font-bold"
              >
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Email <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      className={` outline-none border ${
                        emailError ? "border-danger" : "border-[#E7CCCC]  "
                      } px-3 py-2 w-full rounded text-sm`}
                      type="text"
                      placeholder="Store Name"
                      value={email}
                      onChange={(v) => setEmail(v.target.value)}
                    />
                    <div className=" text-[8px] text-danger">
                      {emailError ? emailError : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Full Name <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <input
                      className={` outline-none border ${
                        errorfullName ? "border-danger" : "border-[#E7CCCC]  "
                      } px-3 py-2 w-full rounded text-sm`}
                      type="text"
                      placeholder="Store Name"
                      value={fullName}
                      onChange={(v) => setFullName(v.target.value)}
                    />
                    <div className=" text-[8px] text-danger">
                      {errorfullName ? errorfullName : ""}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className=" w-full  md:pr-1 my-2">
                  <div className="text-black font-bold text-sm  mb-1">
                    Phone
                  </div>
                  <div>
                    <input
                      className={` outline-none border ${
                        errorPhone ? "border-danger" : "border-[#E7CCCC] "
                      } px-3 py-2 w-full rounded text-sm`}
                      type="text"
                      placeholder="Store Name"
                      value={phone}
                      onChange={(v) => setPhone(v.target.value)}
                    />
                    <div className=" text-[8px] text-danger">
                      {errorPhone ? errorPhone : ""}
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd("true");
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                var check = true;

                setErrorfullName("");

                setEmailError("");
                setErrorPhone("");

                if (fullName === "") {
                  setErrorfullName("This field is required.");
                  check = false;
                }
                if (email === "") {
                  setEmailError("This field is required.");
                  check = false;
                }
                if (phone === "") {
                  setErrorPhone("This field is required.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAdd(true);
                } else {
                  toast.error("Some fields are mandatory please check");
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
        {/*  */}
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Are you sure you want cancel this information ?"
              : "Are you sure you want add this client ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setFullName("");
              setErrorfullName("");

              setEmail("");
              setEmailError("");

              setPhone("");
              setErrorPhone("");

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewClient({
                  full_name: fullName ?? "",
                  phone: phone ?? "",
                  email: email ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddClientScreen;
