import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import { getListUsers } from "../../redux/actions/userActions";

function UserScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const page = location.search.split("&")[1]
    ? location.search.split("&")[1].split("=")[1]
    : 1;
  const dispatch = useDispatch();
  const [status, setStatus] = useState("all");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listUsers = useSelector((state) => state.usersList);
  const { users, loadingUsers, errorUsers, pages } = listUsers;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListUsers(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Users List</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Users Management
            </h4>
            <Link
              to={"/users/add"}
              className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Link>
          </div>

          {/* list */}
          {loadingUsers ? (
            <Loader />
          ) : errorUsers ? (
            <Alert type="error" message={errorUsers} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      N°
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      First Name
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Last Name
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Phone
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Role
                    </th>
                    <th className="py-4 px-4 font-bold text-black text-xs w-max">
                      Operations
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {users?.map((user, index) => (
                    <tr key={index}>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max  ">{user.id}</p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {user.first_name ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {user.last_name ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {user.email ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {user.phone ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {user.role ?? "---"}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max flex flex-row  ">
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/users/edit/" + user.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={`/settings/users?status=${status}&`}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default UserScreen;
