import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addEmployeStore,
  deleteEmployeStore,
  getDetailStore,
  getListEmployeDisponibleStores,
  getListEmployeStores,
  updateStore,
} from "../../redux/actions/storeActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { baseURLFile } from "../../constants";
import Select from "react-select";

function EditStoreScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [eventType, setEventType] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [employeId, setEmployeId] = useState("");
  const [confirmOperation, setConfirmOperation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const [storeName, setStoreName] = useState("");
  const [storeNameError, setStoreNameError] = useState("");

  const [storeLogo, setStoreLogo] = useState("");
  const [storeLogoValue, setStoreLogoValue] = useState("");
  const [storeLogoError, setStoreLogoError] = useState("");

  const [employeSelect, setEmployeSelect] = useState("");
  const [employeSelectError, setEmployeSelectError] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const storeDetail = useSelector((state) => state.detailStore);
  const { loadingDetailStore, errorDetailStore, successDetailStore, store } =
    storeDetail;

  const storeUpdate = useSelector((state) => state.updateStore);
  const { loadingUpdateStore, errorUpdateStore, successUpdateStore } =
    storeUpdate;
  const listEmployeStore = useSelector((state) => state.employeStoreList);
  const {
    employes,
    loadingEmployeStore,
    successEmployeStore,
    errorEmployeStore,
  } = listEmployeStore;

  const employeStoreDelete = useSelector((state) => state.deleteEmployeStore);
  const {
    loadingEmployeStoreDelete,
    errorEmployeStoreDelete,
    successEmployeStoreDelete,
  } = employeStoreDelete;

  const listEmployeDisponibleStore = useSelector(
    (state) => state.employeDisponibleStoreList
  );
  const {
    employesDisponible,
    loadingEmployeDisponibleStore,
    successEmployeDisponibleStore,
    errorEmployeDisponibleStore,
  } = listEmployeDisponibleStore;

  const employeStoreAdd = useSelector((state) => state.addEmployeStore);
  const {
    loadingEmployeStoreAdd,
    errorEmployeStoreAdd,
    successEmployeStoreAdd,
  } = employeStoreAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getDetailStore(id));
      dispatch(getListEmployeStores(id));
      dispatch(getListEmployeDisponibleStores(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (successDetailStore && (store !== null) & (store !== undefined)) {
      setStoreName(store.store_name);
    }
  }, [successDetailStore, store, dispatch]);

  useEffect(() => {
    if (successUpdateStore) {
      setStoreName("");
      setStoreNameError("");
      setStoreLogo("");
      setStoreLogoValue("");
      setStoreLogoError("");
      dispatch(getDetailStore(id));
    }
  }, [successUpdateStore, id, dispatch]);

  useEffect(() => {
    if (successEmployeStoreDelete) {
      dispatch(getListEmployeDisponibleStores(id));
      dispatch(getListEmployeStores(id));
    }
  }, [successEmployeStoreDelete, id, dispatch]);

  useEffect(() => {
    if (successEmployeStoreAdd) {
      dispatch(getListEmployeDisponibleStores(id));
      dispatch(getListEmployeStores(id));
      setEmployeSelect("");
    }
  }, [successEmployeStoreAdd, id, dispatch]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/stores/">
            <div className="">Stores</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Update</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Store Update
            </h4>
          </div>
          {/*  */}

          <div className="flex flex-col ">
            <div className=" w-full px-1 py-1">
              {loadingDetailStore ? (
                <Loader />
              ) : errorDetailStore ? (
                <Alert message={errorDetailStore} />
              ) : (
                <LayoutSection
                  title="Store Informations "
                  styles="bg-primary text-white font-bold"
                >
                  {store.store_logo ? (
                    <div className="md:py-2 md:flex mb-4">
                      <a href={baseURLFile + store.store_logo}>
                        <img
                          className="shadow-1 p-2 rounded size-30 bg-white"
                          src={baseURLFile + store.store_logo}
                        />
                      </a>
                    </div>
                  ) : null}
                  <div className=" w-full  md:pr-1 my-1">
                    <div className="text-black font-bold text-sm  mb-1">
                      Store Name <strong className="text-danger">*</strong>
                    </div>
                    <div>
                      <input
                        className={` outline-none border ${
                          storeNameError ? "border-danger" : "border-[#E7CCCC]"
                        } px-3 py-2 w-full rounded text-sm`}
                        type="text"
                        placeholder="Store Name"
                        value={storeName}
                        onChange={(v) => setStoreName(v.target.value)}
                      />
                      <div className=" text-[8px] text-danger">
                        {storeNameError ? storeNameError : ""}
                      </div>
                    </div>
                  </div>
                  <div className=" w-full  md:pr-1 my-1">
                    <div className="text-black font-bold text-sm  mb-1">
                      Store Logo
                    </div>
                    <div>
                      <input
                        className={` outline-none border ${
                          storeLogoError ? "border-danger" : "border-[#E7CCCC]"
                        } px-3 py-2 w-full rounded text-sm`}
                        type="file"
                        placeholder="Store Logo"
                        accept="image/*"
                        value={storeLogoValue}
                        onChange={(v) => {
                          setStoreLogo(v.target.files[0]);
                          setStoreLogoValue(v.target.value);
                        }}
                      />
                      <div className=" text-[8px] text-danger">
                        {storeLogoError ? storeLogoError : ""}
                      </div>
                    </div>
                  </div>
                  <div className="py-2 my-2 flex  flex-row justify-end md:text-base text-sm">
                    <button
                      onClick={() => {
                        setEventType("cancel");
                        setIsUpdate("true");
                      }}
                      className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        setEventType("update-store");
                        setIsUpdate("true");
                      }}
                      className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1"
                    >
                      Update Store
                    </button>
                  </div>
                </LayoutSection>
              )}
            </div>
            {/*  */}
            <div className="w-full px-1 py-1 mt-5">
              <LayoutSection
                title="Store Employees "
                styles="bg-danger  text-white font-bold"
              >
                {/* add employe */}
                <div className="my-4 ">
                  <div className="md:py-2 md:flex ">
                    <div className="w-full md:pr-1 my-1">
                      <div className="text-black font-bold text-sm  mb-1">
                        Employe <strong className="text-danger">*</strong>
                      </div>
                      <div>
                        <Select
                          value={employeSelect}
                          onChange={(option) => {
                            setEmployeSelect(option);
                          }}
                          className="text-sm"
                          options={employesDisponible?.map((item) => ({
                            value: item.id,
                            label:
                              item.first_name +
                              " " +
                              item.last_name +
                              " ( " +
                              item.role +
                              " ) ",
                          }))}
                          filterOption={(option, inputValue) =>
                            option.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          }
                          placeholder="Select Employe..."
                          isSearchable
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              background: "#fff",
                              border: employeSelectError
                                ? "1px solid #d34053"
                                : "1px solid #F1F3FF",
                              boxShadow: state.isFocused ? "none" : "none",
                              "&:hover": {
                                border: "1px solid #F1F3FF",
                              },
                            }),
                            option: (base) => ({
                              ...base,
                              display: "flex",
                              alignItems: "center",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              display: "flex",
                              alignItems: "center",
                            }),
                          }}
                        />

                        <div className=" text-[8px] text-danger">
                          {employeSelectError ? employeSelectError : ""}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex-1"></div>
                  </div>
                  <div className="md:py-2 flex  flex-row justify-start  md:text-base text-sm">
                    <button
                      onClick={() => {
                        setEmployeSelectError("");
                        if (
                          employeSelect === "" ||
                          employeSelect.value === ""
                        ) {
                          setEmployeSelectError("This field is required.");
                          toast.error("Some fields are mandatory please check");
                        } else {
                          setEventType("add-employe-store");
                          setConfirmOperation(true);
                        }
                      }}
                      className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1"
                    >
                      Add
                    </button>
                    <button
                      onClick={() => {
                        setEventType("");
                        setConfirmOperation(false);
                        setEmployeSelect("");
                        setEmployeSelectError("");
                      }}
                      className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <hr />
                {/* list employes */}
                {loadingEmployeStore ? (
                  <Loader />
                ) : errorEmployeStore ? (
                  <Alert type="error" message={errorEmployeStore} />
                ) : (
                  <div className="max-w-full overflow-x-auto mt-3">
                    <table className="w-full table-auto">
                      <thead>
                        <tr className="bg-gray-2 text-left ">
                          <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max ">
                            N°
                          </th>
                          <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                            Full Name
                          </th>
                          <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max">
                            Email
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Phone
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Role
                          </th>
                          <th className="py-4 px-4 font-bold text-black text-xs w-max">
                            Operations
                          </th>
                        </tr>
                      </thead>
                      {/*  */}
                      <tbody>
                        {employes?.map((employe, index) => (
                          <tr key={index}>
                            <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                              <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                                <div>{employe.id}</div>
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {employe.employe?.first_name ?? "---"}{" "}
                                {employe.employe?.last_name ?? ""}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {employe.employe?.email ?? "---"}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {employe.employe?.phone ?? "---"}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {employe.employe?.role ?? "---"}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max flex flex-row  ">
                                {/* update */}

                                {/* delete */}
                                <button
                                  className="mx-1 delete-class"
                                  onClick={() => {
                                    setEventType("delete");
                                    setEmployeId(employe.id);
                                    setConfirmOperation(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr className="h-11"></tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </LayoutSection>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/* update store */}
      <ConfirmationModal
        isOpen={isUpdate}
        message={
          eventType === "cancel"
            ? "Are you sure you want to cancel this information?"
            : "Are you sure you want to update this Store ?"
        }
        onConfirm={async () => {
          if (eventType === "cancel") {
            setStoreName("");
            setStoreNameError("");

            dispatch(getDetailStore(id));

            setIsUpdate(false);
            setEventType("");
            setIsLoading(false);
          } else if (eventType === "update-store") {
            var check = true;

            setStoreNameError("");
            if (storeName === "") {
              check = false;
              setStoreNameError("This field is required.");
            }

            if (check) {
              setIsLoading(true);
              await dispatch(
                updateStore(id, {
                  store_name: storeName,
                  store_logo: storeLogo,
                })
              ).then(() => {});
              setIsLoading(false);
              setEventType("");
              setIsUpdate(false);
            } else {
              toast.error("Some fields are mandatory please check");
              setIsUpdate(false);
              setEventType("");
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setEventType("");
            setIsUpdate(false);
          }
        }}
        onCancel={() => {
          setIsUpdate(false);
          setEventType("");
          setIsLoading(false);
        }}
        loadEvent={isLoading}
      />

      {/* operation employe store */}
      <ConfirmationModal
        isOpen={confirmOperation}
        message={
          eventType === "delete"
            ? "Are you sure you want delete this employe?"
            : eventType === "add-employe-store"
            ? "Are you sure you want add this employe?"
            : "Are you sure you want to confirm this operation ?"
        }
        onConfirm={async () => {
          if (eventType === "delete" && employeId !== "") {
            setLoadEvent(true);
            await dispatch(deleteEmployeStore(employeId)).then(() => {});
            setLoadEvent(false);
            setEventType("");
            setConfirmOperation(false);
          } else if (
            eventType === "add-employe-store" &&
            employeSelect !== ""
          ) {
            setLoadEvent(true);
            await dispatch(addEmployeStore(id, employeSelect.value)).then(
              () => {}
            );
            setLoadEvent(false);
            setEventType("");
            setConfirmOperation(false);
          } else {
            setLoadEvent(false);
            setEventType("");
            setConfirmOperation(false);
          }
        }}
        onCancel={() => {
          setConfirmOperation(false);
          setEventType("");
          setLoadEvent(false);
        }}
        loadEvent={loadEvent}
      />
    </DefaultLayout>
  );
}

export default EditStoreScreen;
