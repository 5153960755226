import { toast } from "react-toastify";

import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  //
  STORE_ADD_REQUEST,
  STORE_ADD_SUCCESS,
  STORE_ADD_FAIL,
  //
  STORE_DETAIL_REQUEST,
  STORE_DETAIL_SUCCESS,
  STORE_DETAIL_FAIL,
  //
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_UPDATE_FAIL,
  //
  STORE_DELETE_REQUEST,
  STORE_DELETE_SUCCESS,
  STORE_DELETE_FAIL,
  //
  STORE_EMPLOYE_LIST_REQUEST,
  STORE_EMPLOYE_LIST_SUCCESS,
  STORE_EMPLOYE_LIST_FAIL,
  //
  STORE_EMPLOYE_DELETE_REQUEST,
  STORE_EMPLOYE_DELETE_SUCCESS,
  STORE_EMPLOYE_DELETE_FAIL,
  //
  STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST,
  STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS,
  STORE_EMPLOYE_DISPONIBLE_LIST_FAIL,
  //
  STORE_EMPLOYE_ADD_REQUEST,
  STORE_EMPLOYE_ADD_SUCCESS,
  STORE_EMPLOYE_ADD_FAIL,
  //
} from "../constants/storeConstants";

export const addEmployeStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_EMPLOYE_ADD_REQUEST:
      return { loadingEmployeStoreAdd: true };
    case STORE_EMPLOYE_ADD_SUCCESS:
      toast.success("this Employe has been confirmed");
      return {
        loadingEmployeStoreAdd: false,
        successEmployeStoreAdd: true,
      };
    case STORE_EMPLOYE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingEmployeStoreAdd: false,
        successEmployeStoreAdd: false,
        errorEmployeStoreAdd: action.payload,
      };
    default:
      return state;
  }
};

export const employeDisponibleStoreListReducer = (
  state = { employesDisponible: [] },
  action
) => {
  switch (action.type) {
    case STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST:
      return { loadingEmployeDisponibleStore: true, employesDisponible: [] };
    case STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS:
      return {
        loadingEmployeDisponibleStore: false,
        successEmployeDisponibleStore: true,
        employesDisponible: action.payload.employes,
      };
    case STORE_EMPLOYE_DISPONIBLE_LIST_FAIL:
      return {
        loadingEmployeDisponibleStore: false,
        errorEmployeDisponibleStore: action.payload,
        successEmployeDisponibleStore: false,
      };
    default:
      return state;
  }
};

export const deleteEmployeStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_EMPLOYE_DELETE_REQUEST:
      return { loadingEmployeStoreDelete: true };
    case STORE_EMPLOYE_DELETE_SUCCESS:
      toast.success("this Employe has been deleted");
      return {
        loadingEmployeStoreDelete: false,
        successEmployeStoreDelete: true,
      };
    case STORE_EMPLOYE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingEmployeStoreDelete: false,
        successEmployeStoreDelete: false,
        errorEmployeStoreDelete: action.payload,
      };
    default:
      return state;
  }
};

export const employeStoreListReducer = (state = { employes: [] }, action) => {
  switch (action.type) {
    case STORE_EMPLOYE_LIST_REQUEST:
      return { loadingEmployeStore: true, employes: [] };
    case STORE_EMPLOYE_LIST_SUCCESS:
      return {
        loadingEmployeStore: false,
        successEmployeStore: true,
        employes: action.payload.employes,
      };
    case STORE_EMPLOYE_LIST_FAIL:
      return {
        loadingEmployeStore: false,
        errorEmployeStore: action.payload,
        successEmployeStore: false,
      };
    default:
      return state;
  }
};

export const addStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_ADD_REQUEST:
      return { loadingAddStore: true };
    case STORE_ADD_SUCCESS:
      toast.success("this store has been added");
      return {
        loadingAddStore: false,
        successAddStore: true,
      };
    case STORE_ADD_FAIL:
      return {
        loadingAddStore: false,
        successAddStore: false,
        errorAddStore: action.payload,
      };
    default:
      return state;
  }
};

export const updateStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_UPDATE_REQUEST:
      return { loadingUpdateStore: true };
    case STORE_UPDATE_SUCCESS:
      toast.success("this store has been updated");
      return {
        loadingUpdateStore: false,
        successUpdateStore: true,
      };
    case STORE_UPDATE_FAIL:
      return {
        loadingUpdateStore: false,
        successUpdateStore: false,
        errorUpdateStore: action.payload,
      };
    default:
      return state;
  }
};

export const detailStoreReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case STORE_DETAIL_REQUEST:
      return { loadingDetailStore: true };
    case STORE_DETAIL_SUCCESS:
      return {
        loadingDetailStore: false,
        successDetailStore: true,
        store: action.payload,
      };
    case STORE_DETAIL_FAIL:
      return {
        loadingDetailStore: false,
        successDetailStore: false,
        errorDetailStore: action.payload,
      };
    default:
      return state;
  }
};

export const deleteStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_DELETE_REQUEST:
      return { loadingStoreDelete: true };
    case STORE_DELETE_SUCCESS:
      toast.success("this store has been deleted");
      return {
        loadingStoreDelete: false,
        successStoreDelete: true,
      };
    case STORE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingStoreDelete: false,
        successStoreDelete: false,
        errorStoreDelete: action.payload,
      };
    default:
      return state;
  }
};

export const storeListReducer = (state = { stores: [] }, action) => {
  switch (action.type) {
    case STORE_LIST_REQUEST:
      return { loadingStores: true, stores: [] };
    case STORE_LIST_SUCCESS:
      return {
        loadingStores: false,
        successStores: true,
        stores: action.payload.stores,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case STORE_LIST_FAIL:
      return {
        loadingStores: false,
        errorStores: action.payload,
        successStores: false,
      };
    default:
      return state;
  }
};
