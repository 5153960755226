import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import LayoutSection from "../../components/LayoutSection";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import {
  addNewUser,
  getUserDetail,
  updateUserDetail,
} from "../../redux/actions/userActions";
import InputModel from "../../components/InputModel";

function EditUserScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [role, setRole] = useState("");
  const [errorRole, setErrorRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const [isAddUser, setIsAddUser] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventMode, setEventMode] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userAdd = useSelector((state) => state.createNewUser);
  const { loadingUserAdd, errorUserAdd, successUserAdd } = userAdd;

  const getUser = useSelector((state) => state.getDetailUser);
  const { loadingUserDetail, errorUserDetail, successUserDetail, userDetail } =
    getUser;

  const updateUser = useSelector((state) => state.updateDetailUser);
  const {
    loadingUserDetailUpdate,
    errorUserDetailUpdate,
    successUserDetailUpdate,
  } = updateUser;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getUserDetail(id));
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (userDetail !== null && userDetail !== undefined) {
      setFirstName(userDetail.first_name ?? "");
      setLastName(userDetail.last_name ?? "");
      setEmail(userDetail.email ?? "");
      setPhone(userDetail.phone ?? "");
      setRole(userDetail.role ?? "");
    }
  }, [userDetail]);

  useEffect(() => {
    if (successUserDetailUpdate) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setPassword("");
      setRole("");
      setConfirmPassword("");
      dispatch(getUserDetail(id));
      setIsAddUser(false);
      setEventMode("");
      setLoadEvent(false);
    }
  }, [successUserDetailUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/settings/users">
            <div className="">Users List</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Update User</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Update user
            </h4>
          </div>
          {/*  */}
          <LayoutSection title="Personal informations">
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Role*"
                type="select"
                placeholder=""
                value={role}
                onChange={(v) => setRole(v.target.value)}
                error={errorRole}
                options={[
                  {
                    value: "ADMIN",
                    label: "ADMIN",
                  },
                  {
                    value: "CREATOR",
                    label: "CREATOR",
                  },
                  {
                    value: "PREPARATOR",
                    label: "PREPARATOR",
                  },
                  {
                    value: "SHIPPING",
                    label: "SHIPPING",
                  },
                  {
                    value: "CHECKER",
                    label: "CHECKER",
                  },
                ]}
              />
            </div>

            {/* fisrt name & last name */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="First Name*"
                type="text"
                placeholder=""
                value={firstName}
                onChange={(v) => setFirstName(v.target.value)}
                error={errorFirstName}
              />
              <InputModel
                label="Last Name*"
                type="text"
                placeholder=""
                value={lastName}
                onChange={(v) => setLastName(v.target.value)}
                error={errorLastName}
              />
            </div>
            {/* phone and mail */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Email*"
                type="text"
                placeholder=""
                disabled={true}
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                error={errorEmail}
              />
              <InputModel
                label="Phone*"
                type="text"
                placeholder=""
                value={phone}
                onChange={(v) => setPhone(v.target.value)}
                error={errorPhone}
              />
            </div>

            {/* password */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Password"
                type="password"
                placeholder=""
                value={password}
                onChange={(v) => setPassword(v.target.value)}
                error={errorPassword}
              />
              <InputModel
                label="Confirm Password"
                type="password"
                placeholder=""
                value={confirmPassword}
                onChange={(v) => setConfirmPassword(v.target.value)}
                error={errorConfirmPassword}
              />
            </div>
          </LayoutSection>

          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventMode("cancel");
                setIsAddUser(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                var check = true;
                setErrorRole("");
                setErrorFirstName("");
                setErrorLastName("");
                setErrorPhone("");
                setErrorEmail("");
                setErrorPassword("");
                setErrorConfirmPassword("");
                if (role === "") {
                  setErrorRole("This field is required.");
                  check = false;
                }
                if (firstName === "") {
                  setErrorFirstName("This field is required.");
                  check = false;
                }
                if (lastName === "") {
                  setErrorLastName("This field is required.");
                  check = false;
                }
                if (phone === "") {
                  setErrorPhone("This field is required.");
                  check = false;
                }
                if (email === "") {
                  setErrorEmail("This field is required.");
                  check = false;
                }

                if (password !== "") {
                  if (confirmPassword === "") {
                    setErrorConfirmPassword("This field is required.");
                    check = false;
                  }

                  if (password !== confirmPassword) {
                    setErrorPassword("Please confirm the password.");
                    check = false;
                  }
                }
                if (check) {
                  setEventMode("update");
                  setIsAddUser(true);
                } else {
                  toast.error("Some fields are mandatory please check");
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Update
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddUser}
          message={
            eventMode === "cancel"
              ? "Are you sure you want to cancel this information?"
              : "Are you sure you want to update this user?"
          }
          onConfirm={async () => {
            if (eventMode === "cancel") {
              setRole("");
              setErrorRole("");
              setFirstName("");
              setErrorFirstName("");
              setLastName("");
              setErrorLastName("");
              setPhone("");
              setErrorPhone("");
              setEmail("");
              setErrorEmail("");
              setPassword("");
              setErrorPassword("");
              setConfirmPassword("");
              setErrorConfirmPassword("");
              dispatch(getUserDetail(id));
              setIsAddUser(false);
              setEventMode("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateUserDetail(id, {
                  first_name: firstName,
                  last_name: lastName,
                  phone: phone,
                  role: role,
                  password: password,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventMode("");
            }
          }}
          onCancel={() => {
            setIsAddUser(false);
            setEventMode("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditUserScreen;
