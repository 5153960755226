import axios from "../../axios";
import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  //
  STORE_ADD_REQUEST,
  STORE_ADD_SUCCESS,
  STORE_ADD_FAIL,
  //
  STORE_DETAIL_REQUEST,
  STORE_DETAIL_SUCCESS,
  STORE_DETAIL_FAIL,
  //
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_UPDATE_FAIL,
  //
  STORE_DELETE_REQUEST,
  STORE_DELETE_SUCCESS,
  STORE_DELETE_FAIL,
  //
  STORE_EMPLOYE_LIST_REQUEST,
  STORE_EMPLOYE_LIST_SUCCESS,
  STORE_EMPLOYE_LIST_FAIL,
  //
  STORE_EMPLOYE_DELETE_REQUEST,
  STORE_EMPLOYE_DELETE_SUCCESS,
  STORE_EMPLOYE_DELETE_FAIL,
  //
  STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST,
  STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS,
  STORE_EMPLOYE_DISPONIBLE_LIST_FAIL,
  //
  STORE_EMPLOYE_ADD_REQUEST,
  STORE_EMPLOYE_ADD_SUCCESS,
  STORE_EMPLOYE_ADD_FAIL,
  //
} from "../constants/storeConstants";

// delete employe store
export const addEmployeStore = (id, employe) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_EMPLOYE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/stores/employes/add/${id}/`,
      {
        employe: employe,
      },
      config
    );

    dispatch({
      type: STORE_EMPLOYE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_EMPLOYE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getListEmployeDisponibleStores =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/stores/employes/disponible/${id}/`,
        config
      );

      dispatch({
        type: STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoStoreBrass");
          document.location.href = "/";
        }
      }
      dispatch({
        type: STORE_EMPLOYE_DISPONIBLE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// delete employe store
export const deleteEmployeStore = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_EMPLOYE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/stores/employes/delete/${id}/`,
      config
    );

    dispatch({
      type: STORE_EMPLOYE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_EMPLOYE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list employes store
export const getListEmployeStores = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_EMPLOYE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/stores/employes/${id}/`, config);

    dispatch({
      type: STORE_EMPLOYE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_EMPLOYE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add store
export const createNewStore = (store) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/stores/add/`, store, config);

    dispatch({
      type: STORE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update
export const updateStore = (id, store) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/stores/update/${id}/`, store, config);

    dispatch({
      type: STORE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail
export const getDetailStore = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/stores/detail/${id}/`, config);

    dispatch({
      type: STORE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete
export const deleteStore = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/stores/delete/${id}/`, config);

    dispatch({
      type: STORE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list stores
export const getListStores = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/stores/?page=${page}`, config);

    dispatch({
      type: STORE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoStoreBrass");
        document.location.href = "/";
      }
    }
    dispatch({
      type: STORE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
