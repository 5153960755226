import React from "react";

function LayoutSection(props) {
  return (
    <div
      className={`border border-gray rounded-md rounded-t-xl shadow-2 my-2 `}
    >
      <div
        className={`py-3 px-4 uppercase  rounded-t-xl  ${
          props.styles ?? "bg-gray"
        } `}
      >
        {props.title}
      </div>
      <div className="p-4 bg-white rounded-b-xl">{props.children}</div>
    </div>
  );
}

export default LayoutSection;
