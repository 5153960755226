import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getListOrdersHistory } from "../../redux/actions/orderActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import { baseURLFile, ReturnStatusValue } from "../../constants";
import { toast } from "react-toastify";

function HistoryScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [itemSelectOrder, setItemSelectOrder] = useState(null);
  const [isItemSelectOrder, setIsItemSelectOrder] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listHistoryOrders = useSelector((state) => state.orderListHistory);
  const {
    orders,
    loadingHistoryOrders,
    errorHistoryOrders,
    successHistoryOrders,
    pages,
  } = listHistoryOrders;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListOrdersHistory(page, "", "", "", ""));
    }
  }, [navigate, userInfo, dispatch, page]);

  const STATUSCOLOR = (status) => {
    if (status === "RETURNED") {
      return "#0000ff";
    } else if (status === "PROCESSING") {
      return "#ffff00";
    } else if (status === "SHIPPED") {
      return "#008000";
    } else if (status === "CANCELED") {
      return "#ff0000";
    } else {
      return "#cdcd1a";
    }
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">History Orders</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black ">
              ORDERS HISTORY
            </h4>
            {/* "SUPERADMIN","ADMIN","CREATOR","PREPARATOR","SHIPPING","CHECKER", */}
          </div>

          {/* list */}
          {loadingHistoryOrders ? (
            <Loader />
          ) : errorHistoryOrders ? (
            <Alert type="error" message={errorHistoryOrders} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Order Number
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Date
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Store
                    </th>
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max">
                      Items
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Client
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Phone
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Statut
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Total Amount
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {orders?.map((order, index) => (
                    <tr
                      key={index}
                      className={
                        order.status === "HOLD"
                          ? "bg-primary bg-opacity-70"
                          : ""
                      }
                    >
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.order_number ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.date ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.store_info?.store_name ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <button
                          onClick={() => {
                            setItemSelectOrder(order);
                            setIsItemSelectOrder(true);
                          }}
                          className="text-black  text-xs w-max   flex flex-row  justify-end  hover:text-opacity-55"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-4 mr-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <div>{order.items} Item(s)</div>
                        </button>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_name ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_email ?? "---"}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_phone ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p
                          className={`text-xs w-max font-bold text-black border-[0.3px] border-black  ${
                            order.status === "RETURNED"
                              ? "bg-primary"
                              : order.status === "PROCESSING"
                              ? "bg-yellow"
                              : order.status === "SHIPPED"
                              ? "bg-green"
                              : order.status === "CANCELED"
                              ? "bg-danger"
                              : "bg-[#cdcd1a]"
                          } px-3 rounded-full py-[2px] `}
                        >
                          {ReturnStatusValue(order.status) ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max ">
                          {parseFloat(order.total_amount).toFixed(2) ?? "0.00"}
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="">
            <Paginate
              route={"/order-history?"}
              search={""}
              page={page}
              pages={pages}
            />
          </div>
        </div>
        {/* buttom dash */}

        {isItemSelectOrder && itemSelectOrder ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20 py-5 ">
            <div className="bg-white p-6 rounded shadow-md md:w-1/2 w-full mx-2 relative max-h-full overflow-y-auto my-3 z-99999 ">
              <button
                onClick={() => {
                  setIsItemSelectOrder(false);
                  setItemSelectOrder(null);
                }}
                className="absolute top-2 right-9 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-8 fixed shadow-1 rounded-full bg-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
              {/*  */}
              <div className="text-xs text-white bg-[#cdcd1a] px-3 rounded-full py-[2px] w-max">
                {ReturnStatusValue(itemSelectOrder.status ?? "") ?? ""}
              </div>
              <div className="border border-[#aeb7c06e] rounded-md mx-2 my-2 ">
                <div className="p-2 bg-[#aeb7c06e] rounded-t-md text-black ">
                  <button
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          itemSelectOrder.shipping_address?.address ?? ""
                        )
                        .then(() => {
                          toast.success("Address has been copied");
                        })
                        .catch((err) => {
                          toast.error("Failed to copy address: ");
                          console.log(err);
                        });
                    }}
                    className="text-sm font-bold my-1 flex flex-row items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                      />
                    </svg>

                    <div className="flex-1">
                      {itemSelectOrder.shipping_address?.address ?? ""}
                    </div>
                  </button>
                  <div className="text-xs my-1 ">
                    {itemSelectOrder.shipping_address?.country ?? ""}
                  </div>
                </div>
                <div className="my-3 mx-4 ">
                  {itemSelectOrder.itemList?.map((item, index) => (
                    <div className="flex flex-row  my-2 border-b border-[#aeb7c06e] py-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          item.product_image
                            ? baseURLFile + item.product_image
                            : item.product_image_url
                        }
                      >
                        <img
                          className="size-10 mx-1 rounded-md"
                          src={
                            item.product_image
                              ? baseURLFile + item.product_image
                              : item.product_image_url
                              ? item.product_image_url
                              : "/assets/placeholder.png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/placeholder.png";
                          }}
                        />
                      </a>
                      <div className="flex-1 mx-2">
                        <div>{item.product_name}</div>
                        <div className="text-xs">{item.variation}</div>
                        <div className="text-xs ">
                          {parseFloat(item.product_price).toFixed(2)}
                        </div>
                      </div>
                      <div className="font-bold">x {item.quantity}</div>
                    </div>
                  ))}
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        ) : null}

        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default HistoryScreen;
