import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";

import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function DashboardScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      navigate("/orders");
    }
  }, [navigate, userInfo, dispatch]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}

        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xlThe Garden">
          {/* list */}
          <div className="flex md:flex-row flex-col justify-between">
            {/* date */}

            {/* search */}
          </div>
          {/*  */}

          {/* images */}
          <div className=" overflow-auto my-3"></div>
          {/*  */}
          <div className="mt-2"></div>
          <div className="h-20"></div>
        </div>

        {/* buttom dash */}
        {/* reservation table */}

        {/*  */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DashboardScreen;
