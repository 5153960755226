import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";
import {
  detailProduct,
  updateProduct,
} from "../../redux/actions/productActions";

function EditProductScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [eventType, setEventType] = useState("");
  const [confirmOperation, setConfirmOperation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");

  const [productImage, setProductImage] = useState("");
  const [productImagev, setProductImagev] = useState("");
  const [productImageError, setProductImageError] = useState("");

  const [productImageUrl, setProductImageUrl] = useState("");
  const [productImageUrlError, setProductImageUrlError] = useState("");

  const [productPrice, setProductPrice] = useState(0);
  const [productPriceError, setProductPriceError] = useState("");

  const [isVariation, setIsVariation] = useState(false);
  const [isVariationError, setIsVariationError] = useState(false);

  const [variationListLast, setVariationListLast] = useState([]);
  const [variationList, setVariationList] = useState([]);

  const [variationName, setVariationName] = useState("");
  const [variationNameError, setVariationNameError] = useState("");

  const [variationPrice, setVariationPrice] = useState(0);
  const [variationPriceError, setVariationPriceError] = useState("");

  const [productQuantity, setProductQuantity] = useState(1);
  const [productQuantityError, setProductQuantityError] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDetail = useSelector((state) => state.detailProduct);
  const {
    successProductDetail,
    loadingProductDetail,
    product,
    errorProductDetail,
  } = productDetail;

  const editProduct = useSelector((state) => state.updateProduct);
  const { loadingProductUpdate, errorProductUpdate, successProductUpdate } =
    editProduct;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailProduct(id));
    }
  }, [navigate, userInfo, id]);

  useEffect(() => {
    if (product && product !== null && product !== undefined) {
      setProductName(product.product_name ?? "");
      setProductPrice(product.product_price);
      setIsVariation(product.is_variation);
      setVariationListLast(product.variation_list);
      setProductImageUrl(product.product_image_url ?? "");
      setProductQuantity(product.quantity);
    }
  }, [product]);

  useEffect(() => {
    if (successProductUpdate) {
      setVariationList([]);
      dispatch(detailProduct(id));
    }
  }, [successProductUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/products/">
            <div className="">Product</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Update Product</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Update Product
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Product Info"
                styles="bg-primary text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Product Name*"
                    type="text"
                    placeholder=""
                    value={productName}
                    onChange={(v) => {
                      setProductName(v.target.value);
                    }}
                    error={productNameError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Product Image"
                    type="file"
                    accept="image/*"
                    placeholder=""
                    value={productImagev}
                    onChange={(v) => {
                      setProductImage(v.target.files[0]);
                      setProductImagev(v.target.value);
                    }}
                    error={productImageError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Product Image Url"
                    type="text"
                    placeholder=""
                    value={productImageUrl}
                    onChange={(v) => {
                      setProductImageUrl(v.target.value);
                    }}
                    error={productImageUrlError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Product Price*"
                    type="number"
                    isPrice={true}
                    min={0}
                    placeholder=""
                    disabled={isVariation}
                    value={productPrice}
                    onChange={(v) => {
                      setProductPrice(v.target.value);
                    }}
                    error={productPriceError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Quantity*"
                    type="number"
                    placeholder=""
                    value={productQuantity}
                    min={0}
                    onChange={(v) => {
                      setProductQuantity(v.target.value);
                    }}
                    error={productQuantityError}
                  />
                </div>
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Variations"
                styles="bg-danger text-white font-bold"
              >
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isVariation}
                    // value={isVariation ? true : false}
                    onChange={(v) => {
                      setIsVariation(!isVariation);
                    }}
                    class="sr-only peer"
                  />
                  <div class="relative w-11 h-5 bg-gray border-primary border peer-focus:ring-4 peer-focus:ring-primary dark:peer-focus:ring-primary rounded-full peer dark:bg-gray peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray peer-checked:bg-primary"></div>
                  <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Have a variation
                  </span>
                </label>
                {isVariationError && (
                  <p className="text-[9px] italic text-danger leading-none mt-0">
                    {isVariationError}
                  </p>
                )}
                {/* list variation last */}
                {isVariation &&
                  variationListLast?.map((variation, index) => (
                    <div
                      className=" flex flex-row items-center my-2 "
                      key={index}
                    >
                      <div className="mx-1">
                        <button
                          className="mx-1 delete-class"
                          onClick={() => {
                            const newVariations = variationListLast.filter(
                              (_, i) => i !== index
                            );
                            setVariationListLast(newVariations);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="flex-1 mx-2 border-l pl-2">
                        <p className="text-sm font-bold text-black">
                          {variation.variation_name}
                        </p>
                        <p className="text-xs font-bold text-black">
                          {parseFloat(variation.variation_price).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
                {/* list variation */}
                {isVariation &&
                  variationList?.map((variation, index) => (
                    <div
                      className=" flex flex-row items-center my-2 "
                      key={index}
                    >
                      <div className="mx-1">
                        <button
                          className="mx-1 delete-class"
                          onClick={() => {
                            const newVariations = variationList.filter(
                              (_, i) => i !== index
                            );
                            setVariationList(newVariations);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="flex-1 mx-2 border-l pl-2">
                        <p className="text-sm font-bold text-black">
                          {variation.variation}
                        </p>
                        <p className="text-xs font-bold text-black">
                          {parseFloat(variation.price).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
                {isVariation && (
                  <div className="md:py-2 md:flex  md:mt-2 mt-4">
                    <InputModel
                      label="Variation Name*"
                      type="text"
                      placeholder=""
                      value={variationName}
                      onChange={(v) => {
                        setVariationName(v.target.value);
                      }}
                      error={variationNameError}
                    />
                  </div>
                )}
                {isVariation && (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Variation Price*"
                      type="number"
                      isPrice={true}
                      min={0}
                      placeholder=""
                      value={variationPrice}
                      onChange={(v) => {
                        setVariationPrice(v.target.value);
                      }}
                      error={variationPriceError}
                    />
                  </div>
                )}
                {isVariation && (
                  <div className="my-2 flex flex-row items-center justify-end">
                    <button
                      onClick={() => {
                        setVariationName("");
                        setVariationNameError("");
                        setVariationPrice(0);
                        setVariationPriceError("");
                      }}
                      className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={async () => {
                        var check = true;
                        setVariationNameError("");
                        setVariationPriceError("");

                        if (variationName === "") {
                          setVariationNameError("This field is required.");
                          check = false;
                        }

                        if (variationPrice === "") {
                          setVariationPriceError("This field is required.");
                          check = false;
                        }

                        if (check) {
                          setVariationList([
                            ...variationList,
                            {
                              variation: variationName,
                              price: variationPrice,
                            },
                          ]);
                          setVariationName("");
                          setVariationNameError("");
                          setVariationPrice(0);
                          setVariationPriceError("");
                        } else {
                          toast.error("Some fields are mandatory please check");
                        }
                      }}
                      className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                      Add
                    </button>
                  </div>
                )}
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setConfirmOperation(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                var check = true;
                setProductNameError("");
                setProductImageError("");
                setProductImageUrlError("");
                setProductPriceError("");
                setIsVariationError(false);
                setVariationNameError("");
                setVariationPriceError("");
                setProductQuantityError("");
                console.log(productImagev);
                console.log(productImageUrl);

                if (productName === "") {
                  setProductNameError("This field is required.");
                  check = false;
                }
                if (productImagev === "" && productImageUrl === "") {
                  setProductImageError("This field is required.");
                  check = false;
                }
                if (
                  !isVariation &&
                  (productPrice === "" || parseFloat(productPrice) <= 0)
                ) {
                  setProductPriceError("This field is required.");
                  check = false;
                }

                if (productQuantity === "" || parseInt(productQuantity) <= 0) {
                  setProductQuantityError("This field is required.");
                  check = false;
                }

                if (check) {
                  // add
                  setEventType("update");
                  setConfirmOperation(true);
                } else {
                  toast.error("Some fields are mandatory please check");
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Update
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={confirmOperation}
          message={
            eventType === "cancel"
              ? "Are you sure you want cancel this info?"
              : "Are you sure you want update this product?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setProductName("");
              setProductNameError("");

              setProductImage("");
              setProductImagev("");
              setProductImageError("");

              setProductImageUrl("");
              setProductImageUrlError("");

              setProductPrice(0);
              setProductPriceError("");

              setIsVariation(false);
              setIsVariationError(false);

              setVariationListLast([]);
              setVariationList([]);

              setVariationName("");
              setVariationNameError("");

              setVariationPrice(0);
              setVariationPriceError("");

              setProductQuantity(1);
              setProductQuantityError("");

              dispatch(detailProduct(id));

              setConfirmOperation(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateProduct(id, {
                  product_name: productName,
                  product_image: productImage,
                  product_image_url: productImageUrl,
                  product_price: productPrice,
                  quantity: productQuantity,
                  is_variation: isVariation ? "True" : "False",
                  items: variationList,
                  lastItems: variationListLast,
                })
              ).then(() => {});
              setConfirmOperation(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setConfirmOperation(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditProductScreen;
